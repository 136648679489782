import React from "react"

import Layout from '../components/Layout'
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Contact = () => {
    return (
        <Layout>
            <SEO title={"Contact"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-16">
                            Contact
                        </h1>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Projects</h3>
                                <p className="body-l mb-4">
                                    Contact us regarding the Remote Brand Sprint and other client work:
                                </p>
                                <a href="mailto:info@remotebrandsprint.com" className="body-l link-arrow">Send Email</a>
                            </div>
                            <div>
                                <h3 className="title-s-b">Collaboration</h3>
                                <p className="body-l mb-4">
                                    Contact us regarding collaboration offers:
                                </p>
                                <a href="mailto:info@remotebrandsprint.com" className="body-l link-arrow">Send Email</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

        </Layout>
    )
}

export default Contact